import React, {useEffect, useState} from 'react';
import '../Stylesheets/CustomForm.scss';

const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
        });
    }

    useEffect(() => {
        if(status === "success") clearFields();
    }, [status])

    const clearFields = () => {
        setEmail('');
    }

    return (
      <form className="mc__form" onSubmit={(e) => handleSubmit(e)}>

        {status === "sending" && (
          <div className="mc__alert mc__alert--sending">
            sending...
          </div>
        )}
        {status === "error" && (
          <div 
            className="mc__alert mc__alert--error"
            dangerouslySetInnerHTML={{ __html: "An error occurred. Please confirm that you are not already subscribed and try again later." }}
          />
        )}
        {status === "success" && (
          <div
            className="mc__alert mc__alert--success"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

        <div className="mc__field-container">
          <input
            onChange={e => setEmail(e.target.value)}
            type="email"
            value={email}
            placeholder="Email Address"
          />

        </div>

        <input className="submitBtn"
          label="subscribe"
          type="submit"
          value="SUBMIT"
        />
      </form>
    );
};

export default CustomForm;