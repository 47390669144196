import React from 'react';
import NavBar from '../Components/NavBar.js';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import '../Stylesheets/Home.scss';
import tokenDistrib from '../assets/token_distrib.svg';
// import mediumLogo from '../assets/bi_medium.svg';
import instaLogo from '../assets/akar-icons_instagram-fill.svg';
import discordLogo from '../assets/akar-icons_discord-fill.svg';
import twitterLogo from '../assets/akar-icons_twitter-fill.svg';
import logo from '../assets/logo_badge.svg';
import MailchimpFormContainer from '../Components/MailchimpFormContainer.js';
import PrivacyPolicy from '../legal/DeSportPrivacyPolicy.pdf';
import TermsConditions from '../legal/DeSportTAndC.pdf';
import RiskDisclaimer from '../legal/DeSportRiskDisclaimer.pdf';
import HarmTeamPic from '../assets/team/me.png';
import KiTeamPic from '../assets/team/ki.png';
import SalTeamPic from '../assets/team/sal.png';
import AndiTeamPic from '../assets/team/andi.png';

import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';
import Connect from '../Components/wallet/Connect.js';

import ThreeDView from '../Components/ThreeDView';

import { MobileView, BrowserView } from "react-device-detect";

import { ImageCard } from '../Components/Card.js';

import ModelImg from '../assets/model.png';

function getLibrary(provider, connector) {
  return new Web3(provider);
}

function LandingPage() {
  return (
    <div className="LandingPage">
      <Web3ReactProvider getLibrary={getLibrary}>
      <div className="vector1-range">
        <NavBar />
        
        <Container className="jumbo-text">
          <h1>THE VIRTUAL COMBAT<br></br> SPORTS UNIVERSE</h1>
          <h3>Powered by NFTs and fueled by $SPORT</h3>
          <h6>DeSport is the first decentralized, blockchain-based combat sports platform. Compete with or trade NFT fighters, and host events in arenas you own for <b>real profit</b>.</h6>
          <Connect />
        </Container>

        <Container className="features" id="features">
          <h2>Features</h2>
          <h3>Fight, recruit, own arenas, and make a real profit.</h3>
          <Row className="features-detail-row">
            <Col className="left-column" sm>
              <h4>Fight</h4>
              <h6>Put your one-of-a-kind NFT fighters to work and win big by beating your opponents in Events.</h6>
            </Col>
            <Col className="left-column" sm>
              <h4>Recruit</h4>
              <h6>Recruit new NFT fighters to your team by generating them. All fighters are unique and tradeable.</h6>
            </Col>
            <Col sm>
              <h4>Own</h4>
              <h6>Host Events in your arenas, take a cut of the winnings by earning a commission, and generate revenue passively.</h6>
            </Col>
          </Row>

          <MobileView>
            <ThreeDView baseUrl="https://bafybeid2lhvrkjt72vn6mo5fidvbvl2hf32wippd5brnh5htltbo2owjha.ipfs.dweb.link/" scale={1.25}/>
          </MobileView>
          <BrowserView>
            <ThreeDView baseUrl="https://bafybeid2lhvrkjt72vn6mo5fidvbvl2hf32wippd5brnh5htltbo2owjha.ipfs.dweb.link/" scale={1.5}/>
          </BrowserView>

        </Container>
      </div>

      <div className="vector6-range">
        <Container className="roadmap" id="roadmap">
          <h2>ROADMAP</h2>
          <Row className="row">
            <Col className="left-column" sm={6}>
              <h3>Q2 2022</h3>
            </Col>
            <Col className="right-column" sm={6}>
              <ul>
                <li><h6>Private Run</h6></li>
                <li><h6>Marketing</h6></li>
              </ul>
            </Col>
          </Row>
          <Row className="row">
            <Col className="left-column" sm={6}>
              <h3>Q3 2022</h3>
            </Col>
            <Col className="right-column" sm={6}>
              <ul>
                <li><h6>IDO</h6></li>
                <li><h6>Fighter NFT Drop</h6></li>
              </ul>
            </Col>
          </Row>
          <Row className="row">
            <Col className="left-column" sm={6}>
              <h3>Q4 2022</h3>
            </Col>
            <Col className="right-column" sm={6}>
              <ul>
                <li><h6>Events Development</h6></li>
                <li><h6>Arena NFT Drop</h6></li>
                <li><h6>Events Early Access Testing</h6></li>
              </ul>
            </Col>
          </Row>
          <Row className="row">
            <Col className="left-column" sm={6}>
              <h3>Q1 2023</h3>
            </Col>
            <Col className="right-column" sm={6}>
              <ul>
                <li><h6>Events Launch</h6></li>
                <li><h6>Recruitment Smart Contracts</h6></li>
                <li><h6>Rewards and Recruitment Early Access Testing</h6></li>
              </ul>
            </Col>
          </Row>
          <Row className="row">
            <Col className="left-column" sm={6}>
              <h3>Q2 2023</h3>
            </Col>
            <Col className="right-column" sm={6}>
              <ul>
                <li><h6>Rewards Program Launch</h6></li>
                <li><h6>Bookmaking Development</h6></li>
              </ul>
            </Col>
          </Row>
          <Row className="row">
            <Col className="left-column" sm={6}>
              <h3>Q3 2023</h3>
            </Col>
            <Col className="right-column" sm={6}>
              <ul>
                <li><h6>Bookmaking Early Access Testing</h6></li>
                <li><h6>Bookmaking Launch</h6></li>
                <li><h6>In-game NFT Marketplace Development</h6></li>

              </ul>
            </Col>
          </Row>
          <Row className="row">
            <Col className="left-column" sm={6}>
              <h3>Q4 2023</h3>
            </Col>
            <Col className="right-column" sm={6}>
              <ul>
                <li><h6>In-game NFT marketplace launch</h6></li>
                <li><h6>Growth Options Exploration</h6></li>
              </ul>
            </Col>
          </Row>

          <div className="model-img-container">
            <img className="model-img" src={ModelImg} />
          </div>

        </Container>
      </div>

      <div className="vector8-range">
        <Container fluid className="token" id="token">
            <h2>$SPORT</h2>
            <h3>The DeSport Token is fuel for the ecosystem.</h3>
            <h4>Holder Benefits</h4>
            <Row className="token-holder-benefits">
              <Col className="left-column" sm={3}>
                  <h4>Access</h4>
                  <h6>Exclusive access to the hottest NFT drops.</h6>
                </Col>
                <Col className="left-column" sm={3}>
                  <h4>Priority</h4>
                  <h6>Be a part of the ecosystem's development by testing features and providing feedback.</h6>
                </Col>
                <Col className="left-column" sm={3}>
                  <h4>Rewards</h4>
                  <h6>Access rewards and referral programs and increase your potential revenue.</h6>
                </Col>
                <Col sm={3}>
                  <h4>Governance</h4>
                  <h6>Inclusion into the decisions that go into building DeSport.</h6>
                </Col>
            </Row>

            <h4>Projected Allocation</h4>
            <h6>subject to change</h6>
          <Container className="token-distribution">
            <img
              src={tokenDistrib}
              className="d-inline-block align-top"
              alt="L"
            />
          </Container>
        </Container>
      </div>

      <Container className="team" id="team">
        <h2>Team</h2>
        <Row>
          <Col sm={3}>
            <ImageCard 
              src={HarmTeamPic} 
              title="Harm" 
              subtitle="Founder" 
              btnImg={twitterLogo} 
              href="https://twitter.com/harmanft" 
            />
          </Col>
          <Col sm={3}>
            <ImageCard 
              src={KiTeamPic} 
              title="Ki" 
              subtitle="Design" 
              btnImg={twitterLogo} 
              href="https://twitter.com/kameleonmode" 
            />
          </Col>
          <Col sm={3}>
            <ImageCard 
              src={SalTeamPic} 
              title="Sal" 
              subtitle="Development" 
              btnImg={twitterLogo} 
              href="https://twitter.com/desportnft" 
            />
          </Col>
          <Col sm={3}>
            <ImageCard 
              src={AndiTeamPic} 
              title="Andi" 
              subtitle="Marketing" 
              btnImg={twitterLogo} 
              href="https://twitter.com/desportnft" 
            />
          </Col>
        </Row>
      </Container>

      <Container className="social" id="social">
        <h2>Join the DeSport Community</h2>
        <Row className="row"> 
          {/* <Col>
            <a href="#" target="_blank">
              <img
                src={mediumLogo}
                width="50px"
                height="50px"
                className="d-inline-block align-top"
                alt="Medium"
              />
            </a>
          </Col> */}
          <Col>
            <a href="https://www.instagram.com/desportnft/" target="_blank">
              <img
                src={instaLogo}
                width="50px"
                height="50px"
                className="d-inline-block align-top"
                alt="Instagram"
              />
            </a>
          </Col>
          <Col>
            <a href="https://discord.gg/pgSCcXH8Ch" target="_blank">
              <img
                src={discordLogo}
                width="50px"
                height="50px"
                className="d-inline-block align-top"
                alt="Discord"
              />
            </a>
          </Col>          
          <Col>
            <a href="https://twitter.com/desportnft" target="_blank">
              <img
                src={twitterLogo}
                width="50px"
                height="50px"
                className="d-inline-block align-top"
                alt="Twitter"
              />
            </a>
          </Col>        
        </Row>
        <Container className="newsletter-signup">
          <h3>BE THE FIRST TO KNOW</h3>
          <Container className="newsletter-form">
            <MailchimpFormContainer />
          </Container>
        </Container>
      </Container>

      <Container fluid className="footer">
        <Row>
          <Col>
          <img
                src={logo}
                className="d-inline-block align-top"
                alt="DeSport"
              />
          </Col>
          <Col><a href={PrivacyPolicy} target="_blank"><h5>Privacy Policy</h5></a></Col>
          <Col><a href={TermsConditions} target="_blank"><h5>Terms & Conditions</h5></a></Col>
          <Col><a href={RiskDisclaimer} target="_blank"><h5>Risk Disclaimer</h5></a></Col>
        </Row>
      </Container>

      </Web3ReactProvider>
    </div>
  );
}

export default LandingPage;
