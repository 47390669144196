import React from 'react';
import { Navbar, Container, Nav, Row, Col } from 'react-bootstrap';
import {BrowserView, MobileView} from 'react-device-detect';
import logo_text from '../assets/logo_text.svg';
import mediumLogo from '../assets/bi_medium.svg';
import instaLogo from '../assets/akar-icons_instagram-fill.svg';
import discordLogo from '../assets/akar-icons_discord-fill.svg';
import twitterLogo from '../assets/akar-icons_twitter-fill.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Stylesheets/NavBar.scss';

function NavBar() {
  return (
    <Navbar className="NavBar" variant="dark" expand="lg">
      <Container>
      <MobileView className="detect-device-view">
          <Navbar.Brand className="header-img" href="#home">
            <img
                src={logo_text}
                className="d-inline-block align-top"
                alt="DeSport"
              />
          </Navbar.Brand> 
        </MobileView>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-center">
            <Nav.Link className="link-text" href="#features">FEATURES</Nav.Link> 
            <Nav.Link className="link-text" href="#roadmap">ROADMAP</Nav.Link>
            <Nav.Link className="link-text" href="#token">TOKEN</Nav.Link>
            <Nav.Link className="link-text" href="#team">TEAM</Nav.Link>
            <BrowserView className="detect-device-view">
              <Navbar.Brand className="header-img" href="#home">
                <img
                    src={logo_text}
                    className="d-inline-block align-top"
                    alt="DeSport"
                  />
              </Navbar.Brand> 
            </BrowserView>
            <Nav.Link className="link-text" to="route" target="_blank" rel="noopener noreferrer" href="https://desport-platform.gitbook.io/desport/">WHITEPAPER</Nav.Link>
            <Nav.Link className="link-text" href="#social">NEWSLETTER</Nav.Link>
            <Nav.Link href="https://www.instagram.com/desportnft/" to="route" target="_blank" rel="noopener noreferrer">
              <img
                src={instaLogo}
                width="20"
                height="20"
                className="d-inline-block align-top"
                alt="Instagram"
              />
            </Nav.Link>
            <Nav.Link href="https://discord.gg/pgSCcXH8Ch" to="route" target="_blank" rel="noopener noreferrer">
              <img
                src={discordLogo}
                width="20"
                height="20"
                className="d-inline-block align-top"
                alt="Discord"
              />
            </Nav.Link>
            <Nav.Link href="https://twitter.com/desportnft" to="route" target="_blank" rel="noopener noreferrer">
              <img
                src={twitterLogo}
                width="20"
                height="20"
                className="d-inline-block align-top"
                alt="Twitter"
              />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
