import './polyfill.js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LandingPage from "./Screens/Home";
import App from "./Screens/App";
import Event from "./Screens/Event";
import Recruit from "./Screens/Recruit";
import PitchDeck from "./Screens/PitchDeck";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/pitchdeck" element={<PitchDeck />} />
        <Route path="/app" element={<App />} />
        <Route path="/app/event" element={<Event />} />
        <Route path="/app/recruit" element={<Recruit />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
