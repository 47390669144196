import React from 'react';
import NavBar from '../Components/NavBar.js';
import Deck from '../assets/PitchDeck.pdf';
import { SimpleCard } from '../Components/Card.js';
import '../Stylesheets/PitchDeck.scss';

function PitchDeck() {
  return (
    <div className="PitchDeck">
      <NavBar />

      <div className="main">
        <SimpleCard 
          title="Pitch Deck" 
          subtitle="Get to know Desport!" 
          href={Deck}
          btnText="PitchDeck.pdf" 
        />
      </div>
      
    </div>
  );
}

export default PitchDeck;
