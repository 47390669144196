import React from 'react';
import NavBar from '../Components/NavBar.js';

function Recruit() {
  return (
    <div className="Recruit">
      <NavBar />
      <h1>Recruit</h1>
    </div>
  );
}

export default Recruit;
