import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { Environment, OrbitControls, Center, Html } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from "react";

import "../Stylesheets/ThreeDView.scss";

const Model = (props) => {
  const gltf = useLoader(GLTFLoader, props.baseUrl);
  return (
    <>
      <primitive object={gltf.scene} scale={props.scale} />
    </>
  );
};

const Box = (props) => {
  return (
    <Html center>Prototype Fighter loading...</Html>
  )
}

export default function ThreeDView(props) {
  return (
    <div className="ThreeDView">
      <Canvas>
        <ambientLight color={0xffffff} intensity={0.5} />
        <pointLight position={[10, 10, 10]} intensity={0.5} />
        <directionalLight color={0xffffff} intensity={0.6} />
        <Suspense fallback={<Box />}>
          <Center>
            <Model baseUrl={props.baseUrl} scale={props.scale}/>
          </Center>
          <OrbitControls />
          {/* <Environment preset="warehouse" background /> */}
        </Suspense>
      </Canvas>
    </div>
  );
}
