import React from 'react';
import NavBar from '../Components/NavBar.js';

function Event() {
  return (
    <div className="Event">
      <NavBar />
      <h1>Event</h1>
    </div>
  );
}

export default Event;
