import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core"
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";

import { MetaMask } from "./Connectors"
import '../../Stylesheets/Connect.scss';
import React, {useState} from "react";
import { Modal, Button} from "react-bootstrap";
import { useEagerConnect, useInactiveListener } from "./hooks";

export default function Home() {
  const { active, account, library, connector, activate, deactivate, error } = useWeb3React()

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager)

  function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
      return "No MetaMask browser extension detected, please install it or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
      return "You're connected to an unsupported network. Please switch to Smart Chain and try again.";
    } else if (error instanceof UserRejectedRequestErrorInjected) {
      return "DeSport was not authorized to access your wallet. Please try again.";
    } else {
      console.error(error);
      return "An unknown error occurred. Check the console for more details.";
    }
  }

  async function connect() {
    handleClose()
    try {
      await activate(MetaMask)
    } catch (ex) {
      console.log(ex)
      console.log("error: " +  error)
    }
  }

  async function disconnect() {
    try {
      deactivate()
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <div className="wallet-integration">
      {!active ? 
        <button onClick={handleShow} className="connectBtn">Connect Wallet</button> : 
        <div>
          <button onClick={disconnect} className="disconnectBtn">Disconnect Wallet</button>
          <br></br><br></br>
          <span className="connected-dot"></span>
          <span className="wallet-address-text"><b>Connected with: </b>{`${account.substring(0, 7)}...${account.substring(
                account.length - 5
              )}`}</span>
        </div>
      }
      <br></br><br></br>
      {error ? <div><span className="error-dot"></span><span className="wallet-address-text"><b>Error: </b>{getErrorMessage(error)}</span></div> : null}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="modal-lid" closeButton>
          <Modal.Title><h4>Connecting MetaMask to DeSport</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          Your MetaMask Wallet must be connected to the <b>Binance Smart Chain</b> network. Follow the steps at: <a href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target="_blank">Connecting MetaMask to Binance Smart Chain</a>.
        </Modal.Body>
        <Modal.Footer className="modal-lid">
          <Button className="connectBtn" variant="primary" onClick={connect}>
            CONNECT WALLET
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}