import React from 'react';
import NavBar from '../Components/NavBar.js';

function App() {
  return (
    <div className="App">
      <NavBar />
      <h1>App</h1>
    </div>
  );
}

export default App;
