import { Card, Button } from 'react-bootstrap';
import '../Stylesheets/Card.scss';

export const ImageCard = (props) => (
    <Card className="image-card">
        <Card.Img className="avatar" variant="top" src={props.src} />
        <Card.Body>
        <Card.Title className="title">{props.title}</Card.Title>
        <Card.Subtitle className="subtitle">{props.subtitle}</Card.Subtitle>
        <Button className="card-btn" variant="dark" rel="noreferrer" target="_blank" href={props.href}>
            <img src={props.btnImg} />
        </Button>
        </Card.Body>
    </Card>
);

export const SimpleCard = (props) => (
    <Card className="simple-card" style={{ width: '18rem' }}>
        <Card.Body>
        <Card.Title className="title">{props.title}</Card.Title>
        <Card.Subtitle className="subtitle">
            {props.subtitle}
        </Card.Subtitle>
        <Button className="card-btn" variant="dark" rel="noreferrer" href={props.href} target = "_blank">{props.btnText}</Button>
        </Card.Body>
    </Card>
);

